import { render, staticRenderFns } from "./historicaltransactions.vue?vue&type=template&id=74663dcc&scoped=true&"
import script from "./historicaltransactions.vue?vue&type=script&lang=js&"
export * from "./historicaltransactions.vue?vue&type=script&lang=js&"
import style0 from "./historicaltransactions.vue?vue&type=style&index=0&id=74663dcc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74663dcc",
  null
  
)

export default component.exports